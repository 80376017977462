.loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loader {
        width: 80px;
        height: 80px;
    }

    .spinner-grow {
        background-color: $primary!important;
    }

    .loader-text {
        color: $primary;
    }
}
