.not-found-message {
    display: flex;
    align-items: center;
}

.mail-to {
    font-size: 24px;
    font-weight: 500;
    color: darkred;
}

.error-page {
    background-color: lightgray;
    width: 100vw !important;

    .error-container {
        text-align: 'center';
    }
}