.edit-best-contribution {
    transition: transform .3s ease-in-out;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100vh;
    background-color: white;
    padding: 30px;
    overflow-y: scroll;
    z-index: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // small screens
    min-width: 100vw;
    transform: translateX(100vw);

    &.show {
        transform: translateX(0px);
    }

    .completion-rate {
        width: 80%;
    }

    input[type='range']::-webkit-slider-thumb {
        background: $primary;
    }

    .areas-of-improvement {
        
        .area-of-improvement {
            padding: 5px 10px;
            margin: 0 5px 5px 0;
            color: white;
        }
    }

    .buttons {
        height: 40px;
        bottom: 30px;

        .button {
            border: 2px solid $primary;
            color: $primary;
            border-radius: 1px;
            background-color: white;
        }
    
        .save {
            background-color: $primary;
            color: white;
            right: 10px;
        }
    }
}

.new-area-of-improvement {
    border: 4px solid lightgrey;
    border-radius: 15px 0 0 0;
    padding: 5px;
    height: 135px;

    .btn-primary {
        background-color: $primary;

        &:hover {
            background-color: $primaryHover;
        }
    }

    .btn-secondary {
        background-color: white;
        border: 1px solid $primary;
        color: $primary;

        &:hover {
            background-color: $primaryHover;
            color: white
        }
    }
}

@media (min-width: 992px) {
    .edit-best-contribution {
        min-width: 450px;
        transform: translateX(460px);
    }
}
