.action-plan {
    padding: 20px 0 0 20px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .title-filters-container {
        flex-direction: column;
        align-items: flex-start;

        .filter-container {
            flex-direction: column;
        }
    }

.action-plan-dropdown {
    margin-bottom: 2px;

    .action-plan-toggle{
        display: flex;
        align-items: center;
        border: none;

        &:hover, &:focus {
            background-color: transparent;
            border: none;
            outline: none;
        }

        .filter-value-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4px;
            font-size: 12px;
            height: 27px;
            background-color: lightgray;
        }
    }

    .action-plan-dropdown-menu {
        max-height: 50vh;
        min-width: 100%;
        overflow-y: scroll;
        text-align: center;

        .action-plan-dropdown-menu-item {
            text-align: center;

            &:hover {
                color: $primary;
                font-weight: normal;
            }

            &:active {
                color: white;
                background-color: $primary;
            }

        }

    }
}

    .filter-button {
        border: 2px solid $primary;
        border-radius: 20px;
        padding: 0 15px;
        height: 35px;
        color: $primary;
        font-size: 15px;
        font-weight: bold;
        margin-left: 7px;
        display: flex;
        align-items: center;

        .action-plan-icon {
            font-size: 20px;
            font-weight: 900;
        }
    }

    .print-button {
        border-color: grey;
        color: grey
    }

    .best-contributions {
        min-height: calc(100vh - 130px);
        height: calc(100vh - 130px);
        overflow-y: auto;

        .best-contribution {
            padding: 20px 30px;

            .title {
                margin-left: -25px;
            }

            .progress {
                width: 50%;
                height: 10px;
                margin-bottom: 6px;

                .progress-bar {
                    background-color: $primary;
                }
            }

            .full-table {
                margin-bottom: 20px;

                .dot {
                    &.not-started {
                        color: $notStarted;
                    }

                    &.in-progress {
                        color: $inProgress;
                    }

                    &.delayed {
                        color: $delayed;
                    }
                }

                .table-title {
                    width: 13%;
                    border-radius: 10px 10px 0 0;
                    margin-left: -5px;
                }

                .table-cell {
                    width: 10%;
                }

                .task-status {
                    width: 7%;
                }

                .task-delete {
                    width: 2%;
                }

                .table-body {
                    box-shadow: inset 0px 0px 0px 4px lightgrey;
                    border-top: none;

                    .status {
                        padding-left: 10px;
                        color: white;
                        box-shadow: inset 0px 0px 0px 3px lightgrey;

                        &.not-started {
                            background-color: $notStarted;
                        }

                        &.in-progress {
                            background-color: $inProgress;
                        }

                        &.delayed {
                            background-color: $delayed;
                        }

                        &.done {
                            background-color: $done;
                        }
                    }
                }
            }
        }
    }

    .new-best-contribution {
        color: $primary;
        padding-bottom: 20px;
    }

}

@media print {

    body {
        margin: 15mm 8mm;
    }

    body, .action-plan, .best-contributions {
        height: auto !important;
        overflow-y: visible !important;
    }

    ::-webkit-scrollbar {
        display: none !important;
    }

    .print-hide {
        display: none !important;
    }

    @page {
        margin: 8mm;
    }

}

.errorMessage {
    color: $error;
    font-size: 13px;
    font-weight: bold;
    margin-top: -12px;
}

.dot {
    &.not-started {
        color: $notStarted;
    }

    &.in-progress {
        color: $inProgress;
    }

    &.delayed {
        color: $delayed;
    }

    &.done {
        color: $done;
    }
}

@media (min-width: 920px) {
    .action-plan{

        .title-filters-container {
            flex-direction: row;

            .filter-container {
                flex-direction: row;
            }
        }

        .action-plan-dropdown {
            margin-bottom: 0;
        }
    }
}

.area-of-improvement-title-edit {
    background-color: transparent;
    height: 25px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid white;
    padding: 0;

    &:focus {
        background-color: transparent;
        height: 25px;
        margin: 0;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid white;
        outline: none;
    }
}

.area-of-improvement-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.area-of-improvement-edit-title-icon {
    margin-top: 0;
    margin-left: 2px;
}
