.my-actions-container {
    border: 1px solid black;
    border-radius: 10px;

    .my-actions {
        width: 100%;

        .late-task {
            color: rgb(200, 52, 52);
        }
    }

}

.progress {
    width: 40%;

    .progress-bar {
        background-color: $primary;
        padding-left: 2px;
        padding-right: 2px;
    }
}

.cursor {
    cursor: pointer;
}

.module-content {
    width: 100%;
    height: 100%;

    .scrollable-module {
        height: calc(100vh - 65px);
        width: 100%;
        overflow-y: scroll !important;
        padding: 2rem;
    }

}

.primary-button{
    border-radius: 1px;
    background-color: $primary;
    color: white;
    box-shadow: none !important;
    border: 2px solid $primary;

    &:hover {
        background-color: $primaryHover;
        border: 2px solid $primaryHover;
    }

    &:focus {
        background-color: $primaryHover !important;
        color:white !important;
        border: 2px solid $primaryHover;
    }

    &:active {
        background-color: $primaryHover !important;
        color:white !important;
        border: 2px solid $primaryHover;
    }

    &:disabled {
        background-color: grey !important;
        color:white !important;
        border: 2px solid grey !important;
    }
}

.secondary-button{
    border: 2px solid $primary;
    border-radius: 1px;
    background-color: white;
    color: $primary;
    box-shadow: none !important;
    border-radius: 1px;

    &:hover {
        background-color: $primaryHover;
        color:white;
        border: 2px solid $primaryHover;
        }

    &:focus {
        background-color: $primaryHover !important;
        color:white !important;
        border: 2px solid $primaryHover !important;
    }

    &:active {
        background-color: $primaryHover !important;
        color:white !important;
        border: 2px solid $primaryHover !important;
    }

    &:disabled {
        background-color: grey !important;
        color:white !important;
        border: 2px solid grey !important;
    }
}

.color-primary {
    color: $primary
}

.add-idea-or-action-icon {
    margin-top: -8px;
    font-size: 9vh;
    color: $primary;
    box-shadow: -23px 108px 45px -96px rgba(0,0,0,0.71);
    &:hover{
        cursor: pointer;
        color: $primaryHover;
    }
}

.add-idea-or-action {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    bottom: 1rem;
    right: 3rem;
    z-index: 300;
    width: 13%;
}

.error-placeholder{
    color: $error;
}

// when edit task or best contribution modals are open everything else disabled
.shadow-background {
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: absolute;
    opacity: 50%;
    z-index: 350;
}

.icon-primary {
    color: $primary;
    cursor: pointer;
    font-size: 20px;

    &:hover {
        color: $primaryHover;
    }
}

.icon-gray {
    color: gray;
    cursor: pointer;

    &:hover {
        color: $primary;
    }
}

.removed-shadow {
    box-shadow: none !important;

    &:focus {
        border-color: $primary;
    }
}

.textbox-dashboard {
    border: 1px solid $primary;
    border-radius: 5px;
    display: block !important;
    white-space: break-spaces !important;
}

.link-container-dashboard {
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;

    .link-title {
        display: block !important;
        white-space: break-spaces !important;
    }

    .column-scrollable {
        padding-right: 0;
    }
}

.box-check { 

    .form-check-input {
        border-radius: 0 !important;
        box-shadow: none !important;

        &:checked {
            background-color: $primary;
            border-color: $primary;
        }

        &:focus {
            border-color: $primary; 
        }
    }
}

.error-border {
    border-color: $error !important;
}

//mobile view
@media (min-width: 0) {
    
    .dashboard-container {
        width: 100%;
        padding: 0.5rem;
        height: calc(100vh - 65px);
        overflow-y: scroll !important;
        overflow-x: hidden !important;
        padding-bottom: 20vh;
    }

    .chart-dashboard {
        height: calc((88vh - 65px) * 0.67);
    }

    .add-idea-or-action {
        width: 40%;
    }

    .home-container {
        flex-direction: column;
        overflow: scroll !important;
    }

    .horizontal-scrollable > .row > .column-scrollable {
        padding-bottom: 0.25rem;
    }

    .goals-and-metrics-chart {
        min-height: 50vh;
    }
}
// screens larger than tablets
@media (min-width: 992px) { 
    .dashboard-container {
        height: calc(100vh - 65px);
        overflow: hidden !important;
    }
}

//tablet and larger devices
@media (min-width: 575.98px) {
    .chart-dashboard {
        height: calc((88vh - 65px) * 0.67);
    }

    .link-scrollable {
        height: calc((88vh - 65px) * 0.2);
        width: 100%;
        overflow: auto;
    }

    .textbox-dashboard-content {
        height: 9vh;
        overflow-y: auto !important;
    }

    .my-actions {
        height: calc((88vh - 65px) * 0.2);
        overflow-y: scroll;
    }

    .add-idea-or-action {
        width: 13%;
    }

    .home-container {
        flex-direction: row;
        overflow: hidden !important;
    }

    .horizontal-scrollable > .row > .column-scrollable {
        padding-bottom: 0;
    }

    .module-content {
        width: calc(100vw - 350px);
    }
}