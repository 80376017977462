.sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 65px);
    padding: 12px;
    box-shadow: 0px 1px 5px 0px rgba(87, 86, 87, 1);
    background-color: #fafafa;
    border-radius: 0;

    .agenda-box {
        overflow-y: auto !important;
    }

    .list-group-item {
        background-color: transparent;
    }
    
    .bi-gear {
        font-size: 25px;
        vertical-align: middle;
    }

    .section,
    .sidebar-list {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .btn {
            color: $primary;
            text-decoration: none;
            text-align: left;
            padding: 0;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }

            &:focus {
                box-shadow: none;
            }

            &.active {
                color: $primary;
                font-weight: bold;
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: 575.98px) { 
    .sidebar {
        min-height: calc(100vh - 65px);
        width: 350px;
    }
}
