.ideas {
    justify-content: space-between;
    align-content: space-between;
    min-height: calc(100vh - 65px);
    height: calc(100vh - 65px);
    min-width:100%;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 20vh !important;

    .card {
        .card-block {
            padding: 10px;
        }
    }

    .idea {
        height: 300px;
        border: 3px solid $primary;
        border-radius: 8px;
        color: black;
        padding: 10px;
        box-shadow: 9px 6px 12px -7px rgba(0,0,0,0.75);
        scrollbar-width: thin;
    
        ::-webkit-scrollbar {
            width: 5px;
        }
    
        ::-webkit-scrollbar-track {
            background: white;
        }
    
        ::-webkit-scrollbar-thumb {
            background-color: $primary!important;
            border-radius: 20px;
            border: 1px solid white;
        }

        .idea-header {
            font-size: 26px;
            font-weight: 500;
        }
        
        .idea-content {
            padding: 10px;
            margin-top: 5px;
            height: 160px;
            word-wrap: break-word;
            overflow-y: scroll;
        }

        .convert-to-action-button {
            text-align: end;
            color: black;
        
            &:hover {
                color: black;
            }
        
            &:focus {
                color: black;
                box-shadow: none;
            }
        
            &:active {
                color: black;
                font-weight: 600;
            }
        }
    }
}