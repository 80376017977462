.edit-task-form {
    transition: transform .3s ease-in-out;
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100vh;
    background-color: white;
    padding: 30px;
    overflow-y: auto;
    z-index: 400;
    // on smaller screens
    transform: translateX(100vw);
    min-width: 100vw;
    padding-bottom: 17vh;

    &.show {
        transform: translateX(0px);
    }

    .form-check-input:checked {
        background-color: $primary;
        border-color: $primary;
        box-shadow: none;
    }

    .textbox {
        height: 50px;
    }

    .button {
        border: 2px solid $primary;
        color: $primary;
        border-radius: 1px;
        background-color: white;
        height: 40px;
    }

    .save {
        background-color: $primary;
        color: white;
    }
  
}

// Datepicker classes and overrides
.datepicker,
.react-datepicker-ignore-onclickoutside {
    border: 1px solid lightgrey;
    border-radius: 3px;
    height: 37px;
    padding-left: 10px;
}

@media  (min-width: 992px) {
    .edit-task-form {
        transform: translateX(460px);
        min-width: 450px;
        box-shadow: -6px 5px 9px -5px rgba(156,156,156,1);
        padding-bottom: 2rem;
    }
}