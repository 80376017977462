.attendance {
    height: calc(100vh - 65px);
    overflow: auto !important;
    padding: 2rem;

    .attendance-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .attendance-previous-meetings {
        margin-top: 2rem;
        padding-bottom: 20vh;
    }

    .check-list {
        max-height: 30vh;
        overflow-y: auto;

        .form-check-input {
            border-radius: 0 !important;
            box-shadow: none !important;

            &:checked {
                background-color: $primary;
                border-color: $primary;
            }

            &:focus {
                border-color: $primary; 
            }
        }
    }

    .notes-textarea {
        width: 90%;
        
        &:focus {
            border-color: $primary;
        }
    }
}

.previous-meetings {
    max-height: 50vh;
    padding-right: 3px;
    overflow-y: auto;

    .meeting {

        .meeting-headline {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: #ECECEC;
            border: 1px solid lightgray;
            align-items: center;
            cursor: pointer;
        }

        .meeting-info {
            display: flex;
            flex-direction: column;
            background-color: #FAFAFA;
            border: 1px solid #ECECEC;
        }
    }
}

@media  (min-width: 992px) {
    .attendance-previous-meetings {
        margin-top: 0;
        width: 40%;
        padding-bottom: 0;
    }

    .attendance-form {
        width: 50%;
    }

    .attendance {

        .attendance-content {
            display: flex;
            flex-direction: row;
        }
    }
}