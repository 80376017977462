.textbox-container {
    height: calc(100vh - 65px);
    margin: 2rem;

    .textbox {
        width: 80%;

        .form-check-input:checked{
            background-color: $primary!important;
            border-color: $primary!important;
        }

        .form-check-input{
            border: 2px solid $primary!important;
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
            box-shadow: none;
        }

        .textarea-input{
            height: 45vh;

            &:focus {
                border-color: $primary;
            }
        } 
    }     
}

@media (min-width: 575.98px) { 
    .textbox-container {
        width: 80%;

        .textbox {
            width: 60%;
        }
    }
}