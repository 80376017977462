.scrollableWrapper{
    overflow: scroll!important;
    
    .usersAdministration{

        .link-to-invite{
            color: $primary;
            &:hover {
                color: $primaryHover;
            }
        }

        .inviteUsers{

            .error-placeholder{
                color: $error;
            }

            .secondary-button{
                border-radius: 1px;
                border: 2px solid $primary;
                height: 40px;
                transition: color .15s ease-in-out,
                background-color .15s ease-in-out,
                border-color .15s ease-in-out,
                box-shadow .15s ease-in-out;
                background-color: white;
                color: $primary;

                &:hover {
                    background-color: $primaryHover;
                    color:white;
                    border-color: $primaryHover;
                }

                &:focus {
                    background-color: $primaryHover;
                    color:white;
                    border-color: $primaryHover;
                    box-shadow: none;
                }
                
            }
        }
    }
}

.close-icon:hover {
    cursor: pointer;
}

.email-box {
    display: flex;
    background-color: lightgray;
    color: black;
    justify-content: center;
    align-items: center;
}

.img-fluid {
    max-width: 40% !important;
}

.list-of-company-admins {
    display: flex;
    flex-direction: column;
    max-height: 20vh;
    overflow-y: scroll;
}

.sortableTable{

    .table-header{
        background-color: #e0e0e0;
        font-weight: normal;
    }
    
    thead{
        tr{
            th{
                padding: 5px;
                vertical-align: middle;

                span{
                    vertical-align: middle;
                }
            }
        }
    }

    tbody{
        tr{
            td{
                padding: 5px;
                vertical-align: middle;

                i{
                    vertical-align: middle;
                }

                span{
                    vertical-align: middle;
                }
            }
        }     
    }

    .sort-icon {
        color: gray;
        font-size: 20px;

        &:hover {
            color: $primary;
            cursor: pointer;
        }
    }

    .radio {
        &:hover {
            color: $primary;
            cursor: pointer;
        }
    }
}

.modal-content {

    .form-check-input {
        border-radius: 0 !important;
        box-shadow: none !important;

        &:checked {
            background-color: $primary;
            border-color: $primary;
        }

        &:focus {
            border-color: $primary; 
        }
    }
}