// Overrides for modal
.modal-header {
    border-bottom: 0 none;
}

.modal-footer {
    border-top: 0 none;
}

.btn-close {
    display: none;
}
// End of overrides

.agenda {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.agenda-info {
    font-size: 25px;
}

.icon {
    opacity: 0;
    color: gray;
}

.visible-icon {
    color: gray;
    font-size: 20px;

    &:hover {
        color: $primary;
    }
}

.section-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
}

.sorting-arrows {
    opacity: 0;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    padding-left: 3px;
}

.section-title {
    font-size: 20px;
    font-weight: 500;
    height: 35px;
}

.hover-area {
    &:hover {
        .icon, .sorting-arrows{
            opacity: 1;
        }
    }
}

.agenda-module {
    height: 22px;
    align-items: center;
}

.agenda-module-edit {
    display: flex;
    font-size: 16px;
    height: 22px;
    padding-left: 5px;

    .input, .select{
        margin-right: 10px;
        width: 320px;
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid gray;
        padding-left: 0;
    }
}

.agenda-section-title-edit {
    background-color: transparent;
    height: 25px;
    width: 350px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid gray;
    padding: 0;

    &:focus {
        background-color: transparent;
        height: 25px;
        width: 350px;
        margin: 0;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid gray;
        outline: none;
    }
}