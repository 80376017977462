.form-wrapper {
    margin: auto auto;
    border: 1px solid rgba(172, 172, 172, 0.979);
    padding: 30px;
    box-shadow: 3px 4px 5px 0px rgba(194,194,194,1);
    border-radius: 8px;
    width: 600px;

    @media only screen and (max-width: 800px) {
        flex-direction: column;
        border: none;
        box-shadow: none;
        width: 100%;
        margin: 0%;
    }

    .credentials-error {
        padding: 7px;
        text-align: center;
        background: #fdd5d8;
        color: #f44455;
    }
}

.sign-up-logo {
    height: 50px;
    margin-bottom: 30px;
}

.sign-up-container {
    overflow-y: scroll;
}

.sign-button {
    border-radius: 1px;
    border: none;
    height: 40px;
    transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
    background-color: $primary;
    color: white;

    &:hover {
        background-color: $primaryHover;
    }

    &:focus {
        background-color: $primaryHover;
    }

    &:disabled {
        background-color: lightgray;
    }
}

.password-group {
    display: flex;
    height: 75px;
    padding-bottom: 20px;
}

.password-message {
    font-size: 13px;
    color: gray;
    margin-top: 10px;
}

.link {
    color: $primary;

    &:hover {
        color: $primaryHover;
    }
}

.password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.success-icon {
    font-size: 10vh;
    color: $primary;
}